import { createApp } from "vue";
import App from "./App.vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import router from "./router";
import store from "./store";
import VueApexCharts from "vue3-apexcharts";

const app = createApp(App);

// Registrar o componente globalmente
app.component('ApexChart', VueApexCharts);

app
  .use(Quasar, quasarUserOptions)
  .use(router)
  .use(store)
  .mount("#app");

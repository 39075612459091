import axios, { AxiosInstance } from "axios";

const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  headers: {
    "Content-type": "application/json",
    'Cache-Control': 'no-cache',
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Credentials": "true",
  },
});

// http.defaults.withCredentials = true;

export default http;
